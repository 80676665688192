import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "contract-order"
};
const _hoisted_2 = {
  class: "tables"
};
const _hoisted_3 = {
  key: 0,
  class: "constTables"
};
const _hoisted_4 = {
  class: "th acea-row row-between-wrapper"
};
const _hoisted_5 = {
  class: "timeW"
};
const _hoisted_6 = {
  class: "oddW"
};
const _hoisted_7 = {
  class: "text_rit"
};
const _hoisted_8 = {
  key: 0,
  class: ""
};
const _hoisted_9 = {
  key: 1,
  class: ""
};
const _hoisted_10 = {
  class: "timeW"
};
const _hoisted_11 = {
  class: "acea-row row-left",
  style: {
    "align-items": "center"
  }
};
const _hoisted_12 = {
  class: "color1 oddW"
};
const _hoisted_13 = {
  class: "text_rit"
};
const _hoisted_14 = {
  class: "pagination"
};
const _hoisted_15 = {
  key: 1,
  class: "no_more"
};
const _hoisted_16 = {
  key: 0,
  class: "constTables constTables_ended"
};
const _hoisted_17 = {
  class: "th acea-row row-between-wrapper"
};
const _hoisted_18 = {
  class: "timeW"
};
const _hoisted_19 = {
  class: "oddW"
};
const _hoisted_20 = {
  class: "text_rit"
};
const _hoisted_21 = {
  key: 0,
  class: ""
};
const _hoisted_22 = {
  key: 1,
  class: ""
};
const _hoisted_23 = {
  class: "timeW"
};
const _hoisted_24 = {
  class: "acea-row row-left",
  style: {
    "align-items": "center"
  }
};
const _hoisted_25 = {
  key: 0,
  class: "oddW color1"
};
const _hoisted_26 = {
  key: 1,
  class: "oddW color2"
};
const _hoisted_27 = {
  class: "text_rit"
};
const _hoisted_28 = {
  class: "pagination"
};
const _hoisted_29 = {
  key: 1,
  class: "no_more"
};
import NP from 'number-precision';
import { router } from '@/router';
import { computed, ref, watch } from 'vue';
import { contractOrder } from '@/http';
import { useUserStore } from "@/stores/user";
import { useRoute } from "vue-router";
export default {
  __name: 'contOrder',
  props: {
    types: Number
  },
  setup(__props) {
    const total = ref(0);
    const pageSize = ref(5);
    const list = ref([]);
    const page = ref([1, 1]);
    const active = ref(0);
    const loading = ref([false, false]);
    const finished = ref([false, false]);
    const userStore = useUserStore();
    /**  @property{Array} tabList = [加密货币,外汇,金子,期货] */
    const tabList = computed(() => userStore.tabList);
    const route = useRoute();
    const props = __props;
    const strIncode = str => {
      if (str.includes('+')) {
        return true;
      } else if (str.includes('-')) {
        return false;
      }
    };
    const typeTos = route.query.order ? parseInt(route.query.order) : 0;
    let tabActive = ref();
    tabActive.value = props.types;
    const setTabs = index => {
      finished.value[active.value] = false;
      loading.value[active.value] = true;
      tabActive.value = index;
      page.value = [1, 1];
      _contractOrder();
    };
    let toBg = ref(false);
    const changes = e => {
      toBg.value = e;
    };
    let show = ref(false);
    const scroll = e => {
      show.value = e.scrollTop > 10;
    };
    const finist = () => {
      page.value = [1, 1];
      _contractOrder();
    };
    const changeList = net => {
      console.log(net);
      page.value[active.value] = net;
      _contractOrder();
    };
    const _contractOrder = () => {
      contractOrder({
        state: active.value,
        page: page.value[active.value],
        perPage: pageSize.value
      }).then(({
        data
      }) => {
        list.value = initList(data.data);
        console.log(list.value);
        total.value = data.total;
      });
    };
    const initList = dataList => {
      /* if (tabActive.value === 0){
           dataList.forEach(item =>{
               console.log(item['product'].category )
           })
           to = dataList.filter(item =>  item['product'].category === 0 || item['product'].category === 2)
       }else {
           to = dataList.filter(item => item['product'].category === 3)
       }*/
      return dataList.filter(item => item['product'].category === tabActive.value);
    };
    const back = () => {
      router.go(-1);
    };
    _contractOrder();
    watch(active, newVal => {
      console.log(newVal);
      _contractOrder();
    });
    return (_ctx, _cache) => {
      const _component_van_count_down = _resolveComponent("van-count-down");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tabs, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => active.value = $event),
        class: "demo-tabs"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: _ctx.$t('in_progress'),
          name: 0
        }, {
          default: _withCtx(() => [list.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('Name')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('amount')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('final_price')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('open')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('direction')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('time')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('leverage')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('expected_profit_loss')), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('handling_fee')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tds acea-row row-between-wrapper",
              key: index
            }, [_createElementVNode("div", null, [item.product.category === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.product.name) + "/USDT ", 1)) : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(item.product.name), 1))]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.amount)) + " (USDT)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.settlement_price) || '-'), 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.buy_price)), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.buy_type === 1 ? _ctx.$t('rise') : _ctx.$t('fall')), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, [_createVNode(_component_van_count_down, {
              style: {
                "color": "#fff"
              },
              time: item.leave_time * 1000,
              format: "ss",
              onFinish: finist
            }, null, 8, ["time"]), _cache[3] || (_cache[3] = _createTextVNode("s"))])]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.lever === '/' ? '-' : item.lever) + _toDisplayString(_ctx.$t('times')), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", null, "+" + _toDisplayString(parseFloat(item.odd)), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(parseFloat(item.fee)), 1)])]);
          }), 128)), _createElementVNode("div", _hoisted_14, [_createVNode(_component_el_pagination, {
            size: "small",
            background: "",
            layout: "prev, pager, next",
            total: total.value,
            class: "mt-4",
            "page-size": pageSize.value,
            "current-page": page.value[0],
            "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => page.value[0] = $event),
            onChange: changeList
          }, null, 8, ["total", "page-size", "current-page"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('no_more')), 1))]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('has_ended'),
          name: 1
        }, {
          default: _withCtx(() => [list.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('Name')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('amount')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('final_price')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('open')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('direction')), 1), _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('time')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('leverage')), 1), _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('actual_profit_and_loss')), 1), _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('handling_fee')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tds acea-row row-between-wrapper",
              key: index
            }, [_createElementVNode("div", null, [item.product.category === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(item.product.name) + "/USDT ", 1)) : (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(item.product.name), 1))]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.amount)) + " (USDT)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.settlement_price) || '-'), 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.buy_price)), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.buy_type === 1 ? _ctx.$t('rise') : _ctx.$t('fall')), 1)]), _createElementVNode("div", _hoisted_23, [_createElementVNode("span", _hoisted_24, _toDisplayString(item.times) + "s ", 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.lever === '/' ? '-' : item.lever) + _toDisplayString(_ctx.$t('times')), 1)]), strIncode(item.odd) ? (_openBlock(), _createElementBlock("div", _hoisted_25, "+" + _toDisplayString(parseFloat(item.odd)), 1)) : _createCommentVNode("", true), !strIncode(item.odd) ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(parseFloat(item.odd)), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_27, [_createElementVNode("span", null, _toDisplayString(parseFloat(item.fee)), 1)])]);
          }), 128)), _createElementVNode("div", _hoisted_28, [_createVNode(_component_el_pagination, {
            size: "small",
            background: "",
            layout: "prev, pager, next",
            total: total.value,
            class: "mt-4",
            "page-size": pageSize.value,
            "current-page": page.value[1],
            "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => page.value[1] = $event),
            onChange: changeList
          }, null, 8, ["total", "page-size", "current-page"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.$t('no_more')), 1))]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["modelValue"])])]);
    };
  }
};